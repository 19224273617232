import React from "react"

import { Link } from "react-router-dom"

const index = () => (
  <main>
    <div className="content-error-area">
      <div
        className="container"
        style={{ height: "100vh" }}
      >
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-xl-8">
            <div className="content-error-item text-center">
              <div className="section-title">
                <h2 className="mb-20">Oops! That page can not be found.</h2>
              </div>
              <p>
                We couldn't find any results for your search. Use more generic
                words or double check your spelling.
              </p>
              <div className="error-btn">
                <Link
                  to="/"
                  className="theme-btn border-btn"
                >
                  Back to homepage
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
)

export default index
