import React from "react"

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import { LazyElement, ScrollToTop } from "./components"
import { useAnalytics } from "./hooks"
import Layout from "./layouts/Layout"
import Error404 from "./pages/errors/404"

const Login = React.lazy(() => import("./pages/auth/Login"))
const Register = React.lazy(() => import("./pages/auth/Register"))
const Home = React.lazy(() => import("./pages/home/Home"))
const About = React.lazy(() => import("./pages/about/About"))
const Insights = React.lazy(() => import("./pages/insights/Insights"))
const VMS = React.lazy(() => import("./pages/vms/VMS"))
const Signage = React.lazy(() => import("./pages/signage/Signage"))
const ContactUS = React.lazy(() => import("./pages/contact/ContactUS"))

const AllRoutes = () => {
  useAnalytics()
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="login"
          element={
            <LazyElement>
              <Login />
            </LazyElement>
          }
        />
        <Route
          path="register"
          element={
            <LazyElement>
              <Register />
            </LazyElement>
          }
        />

        <Route
          path="/"
          element={
            <LazyElement>
              <Layout />
            </LazyElement>
          }
        >
          <Route
            path=""
            element={
              <LazyElement>
                <Home />
              </LazyElement>
            }
          />

          <Route
            path="home"
            element={
              <LazyElement>
                <Home />
              </LazyElement>
            }
          />

          <Route
            path="about"
            element={
              <LazyElement>
                <About />
              </LazyElement>
            }
          />

          <Route
            path="vms"
            element={
              <LazyElement>
                <VMS />
              </LazyElement>
            }
          />

          <Route
            path="signage"
            element={
              <LazyElement>
                <Signage />
              </LazyElement>
            }
          />

          <Route
            path="insights"
            element={
              <LazyElement>
                <Insights />
              </LazyElement>
            }
          />

          <Route
            path="contact"
            element={
              <LazyElement>
                <ContactUS />
              </LazyElement>
            }
          />
        </Route>

        <Route
          path="404"
          element={<Error404 />}
        />

        <Route
          path="*"
          element={
            <Navigate
              to="/404"
              replace
            />
          }
        />
      </Routes>
    </>
  )
}

const RootRoutes = () => (
  <BrowserRouter>
    <AllRoutes />
  </BrowserRouter>
)

export default RootRoutes
