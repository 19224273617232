import React from "react"

import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"

import { Footer, Header } from "../components"

const Layout = () => (
  <Box>
    <Header />

    <Outlet />

    <Footer />
  </Box>
)

export default Layout
