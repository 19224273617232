import React from "react"

import { Link } from "react-router-dom"

import FooterBottom from "./FooterBottom"

const Footer = ({ footerSpace }) => (
  <footer
    className={`${footerSpace || "pt-95 position-relative"}`}
    style={{ backgroundImage: "url('./assets/img/bg/footer-bg.jpg')" }}
  >
    <div className="common-shape-wrapper">
      <div className="common-shape-inner" />
    </div>
    <div className="footer-area pb-60">
      <div className="container-sm">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="widget mb-30">
              <div className="footer-logo mb-10">
                <Link to="/">
                  <img
                    src="./assets/img/logo/logo.png"
                    className="img-fluid footer-logo-img"
                    alt="footer-logo"
                  />
                </Link>
              </div>
              <div className="mt-35 footer-logo-section">
                <ul>
                  <li>
                    <p
                      className="mb-0"
                      style={{ color: "#fff" }}
                    >
                      <strong>Digital Signage</strong>
                    </p>
                    <p>
                      <a href="mailto:sales@madelevator.com">
                        sales@madelevator.com
                      </a>
                    </p>
                  </li>
                  <li>
                    <p
                      className="mb-0"
                      style={{ color: "#fff" }}
                    >
                      <strong>Video & Messaging System</strong>
                    </p>
                    <p>
                      <a href="mailto:vms@madelevator.com">
                        vms@madelevator.com
                      </a>
                    </p>
                  </li>
                  <li>
                    <p
                      className="mb-0"
                      style={{ color: "#fff" }}
                    >
                      <strong>Insights</strong>
                    </p>
                    <p>
                      <a href="mailto:insights@madelevator.com">
                        insights@madelevator.com
                      </a>
                    </p>
                  </li>
                  <li>
                    <p className="mb-0">
                      <a
                        href="https://www.madelevator.com"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#fff" }}
                      >
                        <strong>MAD Elevator Inc.</strong>
                      </a>
                    </p>
                  </li>
                  <li>
                    <p
                      className="mb-0"
                      style={{ color: "#fff" }}
                    >
                      <strong>
                        Mississauga, ON | 6635 Ordan Drive, L5T 1K6
                      </strong>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="widget mb-30 mt-70">
              <h4 className="widget-title mb-35">Links</h4>
              <ul>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/vms">Video & Messaging System</Link>
                </li>
                <li>
                  <Link to="/signage">Digital Signage</Link>
                </li>
                <li>
                  <Link to="/insights">Insights</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="widget widget-contact mb-30 mt-70">
              <p className="mb-45 mt-30">
                DISCLAIMER: The images, renderings, and examples displayed on
                this websitemay not accurately reflect the actual products.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterBottom />
  </footer>
)

export default Footer
