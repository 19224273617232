import React from "react"

const FooterBottom = () => (
  <div className="copyright-area">
    <div className="container-sm">
      <div className="copyright-bg">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="copyright">
              <span>
                ©{" "}
                <a
                  href="https://www.madelevator.com"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "rgb(160, 162, 166)" }}
                >
                  MAD Elevator Inc
                </a>{" "}
                (MAD) {new Date().getFullYear()}. All rights reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default FooterBottom
