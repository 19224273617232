/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react"

import { Link } from "react-router-dom"

import BurgerMenus from "./BurgerMenus"
import HeaderSearch from "./HeaderSearch"

const Component = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)

  const sticky = () => {
    const header = document.querySelector(".transparent-header")
    const scrollTop = window.scrollY
    scrollTop >= 1
      ? header.classList.add("sticky-header")
      : header.classList.remove("sticky-header")
  }

  // Sticky Menu Area start
  useEffect(() => {
    window.addEventListener("scroll", sticky)
    return () => {
      window.removeEventListener("scroll", sticky)
    }
  })

  // Sticky Menu Area End

  return (
    <header>
      <div className="transparent-header">
        <div className="container-fluid">
          <div className="header-space">
            <div className="d-flex justify-content-between align-items-center">
              <div className="ml-10 mr-30">
                <div className="header-logo">
                  <Link to="/">
                    <img
                      src="./assets/img/logo/logo.png"
                      className="img-fluid header-logo-img"
                      alt="logo-img"
                    />
                  </Link>
                </div>
              </div>
              <div>
                <div className="main-menu d-xl-block d-none">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <Link to="/about">ABOUT</Link>
                      </li>
                      <li>
                        <Link to="/vms">VIDEO & MESSAGING SYSTEM</Link>
                      </li>
                      <li>
                        <Link to="/signage">DIGITAL SIGNAGE</Link>
                      </li>
                      <li>
                        <Link to="/insights">INSIGHTS</Link>
                      </li>
                      <li>
                        <Link to="/contact">CONTACT</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="side-menu-icon d-xl-none text-end">
                  <span
                    className="search-btn nav-search search-trigger"
                    onClick={() => {
                      setSearchOpen(!searchOpen)
                    }}
                    role="presentation"
                  >
                    <i className="fas fa-search" />
                  </span>
                  <button
                    className="side-toggle"
                    onClick={() => {
                      setMenuOpen(!menuOpen)
                    }}
                    role="presentation"
                  >
                    <i className="far fa-bars" />
                  </button>
                </div>
              </div>
              <div className="d-none d-xl-block">
                <div className="header-right text-end">
                  <div className="header-search">
                    <span
                      className="search-btn nav-search search-trigger"
                      onClick={() => {
                        setSearchOpen(!searchOpen)
                      }}
                      role="presentation"
                    >
                      <i className="fas fa-search" />
                    </span>
                  </div>
                  <div className="header-btn login-menu">
                    {/* <LoginDialog /> */}
                    <ul>
                      <li className="menu-item-has-children">
                        <button
                          type="button"
                          className="theme-btn theme-btn-small border-btn"
                        >
                          Log In
                        </button>
                        <ul className="sub-menu">
                          <li>
                            <a
                              href="https://www.mosaicone.net"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Login To MosaicOne
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.elevatorinsights.ai"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Login To Insights
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BurgerMenus
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <div
        onClick={() => setMenuOpen(false)}
        role="presentation"
        className={menuOpen ? "body-overlay show" : "body-overlay"}
      />

      <HeaderSearch
        searchOpen={searchOpen}
        setSearchOpen={setSearchOpen}
      />
      <div
        onClick={() => setSearchOpen(false)}
        role="presentation"
        className={searchOpen ? "body-overlay show" : "body-overlay"}
      />
    </header>
  )
}

const Header = () => (
  <React.Suspense fallback="loading">
    <Component />
  </React.Suspense>
)

export default Header
