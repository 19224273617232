/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"

import { NavLink } from "react-router-dom"

const BurgerMenus = ({ setMenuOpen, menuOpen }) => {
  const [login, setLogin] = React.useState(false)
  const openMobileMenu = (menu) => {
    if (menu === "login") {
      setLogin(!login)
    }
  }

  return (
    <div className="fix">
      <div className={menuOpen ? "side-info info-open" : "side-info"}>
        <button
          className="side-info-close"
          onClick={() => setMenuOpen(false)}
        >
          <i className="far fa-times" />
        </button>
        <div className="side-info-content">
          <div className="mm-menu">
            <ul>
              <li>
                <NavLink
                  to="/about"
                  onClick={() => setMenuOpen(false)}
                >
                  {({ isActive }) => (
                    <span className={isActive ? "active" : ""}>ABOUT</span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vms"
                  onClick={() => setMenuOpen(false)}
                >
                  {({ isActive }) => (
                    <span className={isActive ? "active" : ""}>
                      VIDEO & MESSAGING SYSTEM
                    </span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/signage"
                  onClick={() => setMenuOpen(false)}
                >
                  {({ isActive }) => (
                    <span className={isActive ? "active" : ""}>
                      DIGITAL SIGNAGE
                    </span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/insights"
                  onClick={() => setMenuOpen(false)}
                >
                  {({ isActive }) => (
                    <span className={isActive ? "active" : ""}>INSIGHTS</span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  onClick={() => setMenuOpen(false)}
                >
                  {({ isActive }) => (
                    <span className={isActive ? "active" : ""}>CONTACT</span>
                  )}
                </NavLink>
              </li>
              <li className={login ? "has-droupdown active" : "has-droupdown"}>
                <a
                  onClick={() => {
                    openMobileMenu("login")
                  }}
                >
                  Log In
                </a>
                <ul className={login ? "sub-menu active" : "sub-menu"}>
                  <li>
                    <a
                      href="https://www.mosaicone.net"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Login To MosaicOne
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.elevatorinsights.ai"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Login To Insights
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="contact-infos mb-30">
            <div className="contact-list mb-30">
              <h4>Contact Info</h4>
              <ul>
                <li>
                  <p
                    className="mb-0"
                    style={{ color: "#fff" }}
                  >
                    <strong>Digital Signage</strong>
                  </p>
                  <p>
                    <a href="mailto:sales@madelevator.com">
                      sales@madelevator.com
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    className="mb-0"
                    style={{ color: "#fff" }}
                  >
                    <strong>Video & Messaging System</strong>
                  </p>
                  <p>
                    <a href="mailto:vms@madelevator.com">vms@madelevator.com</a>
                  </p>
                </li>
                <li>
                  <p
                    className="mb-0"
                    style={{ color: "#fff" }}
                  >
                    <strong>Insights</strong>
                  </p>
                  <p>
                    <a href="mailto:insights@madelevator.com">
                      insights@madelevator.com
                    </a>
                  </p>
                </li>
                <li>
                  <p className="mb-0">
                    <a
                      href="https://www.madelevator.com"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#fff" }}
                    >
                      <strong>MAD Elevator Inc.</strong>
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    className="mb-0"
                    style={{ color: "#fff" }}
                  >
                    <strong>Mississauga, ON | 6635 Ordan Drive, L5T 1K6</strong>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BurgerMenus
