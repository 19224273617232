import React from "react"

import { Alert, AlertTitle, Box, Button } from "@mui/material"

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
  const handleBack = () => {
    window.history.back()
  }

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2
      }}
    >
      <Alert
        variant="outlined"
        severity="error"
      >
        <AlertTitle>Something went wrong:</AlertTitle>
        {error.message}
      </Alert>

      <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleBack}
        >
          Go back
        </Button>

        <Button
          variant="contained"
          color="error"
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
      </Box>
    </Box>
  )
}

export default ErrorBoundaryFallback
