import React from "react"

const Breadcrumb = ({
  breadcrumbTitle,
  breadcrumbSubTitle,
  breadcrumbImg,
  children
}) => (
  <section
    className="page-title-area breadcrumb-spacing"
    style={{
      backgroundImage: `url("./assets/img/${breadcrumbImg}")`,
      backgroundPosition: "center center"
    }}
  >
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xxl-9">
          <div className="page-title-wrapper text-center">
            <h3 className="page-title mb-25">{breadcrumbTitle}</h3>
            <h5 className="page-subtitle mb-25">{breadcrumbSubTitle}</h5>
            {children}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Breadcrumb
